export const transitionVariants = {
  enter: (custom: { direction: number; size: number }) => ({
    x: custom.direction > 0 ? custom.size : -1 * custom.size, // Enter from right if moving forward, from left if moving back
    opacity: 0,
    transition: { duration: 0.2, ease: 'easeInOut' },
  }),
  center: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.2, ease: 'easeInOut' },
  },
  exit: (custom: { direction: number; size: number }) => ({
    x: custom.direction > 0 ? -10 * custom.size : 10 * custom.size, // Exit to left if moving forward, to right if moving back
    opacity: 0,
    transition: { duration: 0.2, ease: 'easeOut' },
  }),
};
