import React from 'react';
import { PreviewStoreProvider } from 'gatsby-source-prismic';
import smoothscroll from 'smoothscroll-polyfill';
import { CookiesProvider } from 'react-cookie';
import { AuthProvider } from './src/providers/Auth/provider';
import './src/assets/styles/global.css';

export function wrapRootElement({ element }) {
  return (
    <CookiesProvider>
      <AuthProvider>
        <PreviewStoreProvider initialEnabled>{element}</PreviewStoreProvider>
      </AuthProvider>
    </CookiesProvider>
  );
}

export const onClientEntry = () => {
  smoothscroll.polyfill();
};
