import React, { useState, useEffect } from 'react';
import { AuthContext } from './context';
import supabase from '../../auth/client'; // Adjust the import path as necessary
import { AuthState, AuthProviderProps } from './types';

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [session, setSession] = useState<AuthState['session']>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session: sessionData } }) => {
      setSession(sessionData);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, newSession) => {
      setSession(newSession);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const signOut = async () => {
    await supabase.auth.signOut();
    setSession(null);
  };

  return (
    <AuthContext.Provider value={{ session, setSession, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};
