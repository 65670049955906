import colors from './colors/index';

import { textStyles } from './text';
import links from './links';
import { sectionStyles } from './sections';
import { transitionVariants } from './transitions';

const theme = {
  colors,
  fonts: {
    body: '"Basier Square", "Segoe UI", "Trebutchet MS", Tahoma, Helvetica, Verdana, sans-serif',
    heading: '"Gestura Headline", Georgia, serif',
    serif: '"Gestura Text", Georgia, serif',
    mono: '"Basier Square Mono", "Andale Mono", Courier, Lucida, Monaco, "Segoe UI", sans-serif',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  // breakpoints: ['576px', '768px', '992px', '1200px'],
  breakpoints: ['36rem', '48rem', '62rem', '75rem'],
  space: {
    default: '1.25rem',
    normal: '3.75rem',
  },
  paddings: {
    large: {
      desktop: '8rem',
      tablet: '6rem',
      mobile: '6rem',
    },
    standard: {
      desktop: '4rem',
      tablet: '3rem',
      mobile: '3rem',
    },
    none: {
      desktop: 0,
      tablet: 0,
      mobile: 0,
    },
  },
  sizes: {
    container: '79.75rem',
  },
  radii: {
    small: '0.5rem',
    big: '50%',
  },
  transition: '0.3s',
  shorterTransition: '0.2s',
  text: {
    ...textStyles,
  },
  styles: {
    spinner: {
      color: '#2B6CED',
    },
  },
  links,
  sectionStyles,
  transitions: {
    transitionVariants,
  },
};

export default theme;

export type Theme = typeof theme;

export type WithTheme<T = unknown> = { theme: Theme } & T;
